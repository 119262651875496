.o-macaron {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;

  img {
    height: 75px;
    width: 75px;
  }
  .icon {
    @apply absolute;
  }
  .text {
    @apply mx-auto animate-spin-slow;
  }

  @media (min-width: 768px) {
    height: 198px;
    width: 198px;
    img {
      height: 198px;
      width: 198px;
    }
  }
}

.strate-makeithappen {
  .o-macaron {
    display: flex;
    top: -20px;
    right: calc(50% - 90px);
    @media (min-width: 768px) {
      top: -50px;
      right: calc(50% - 225px);
    }
  }
}

.strate-data-lovers {
  .o-macaron {
    display: flex;
    top: 0px;
    left: calc(50% - 150px);
    @media (min-width: 768px) {
      top: 0px;
      left: calc(50% - 500px);
    }
  }
}

.strate-data-family {
  .o-macaron {
    top: -50px;
    right: calc(50% - 150px);
    @media (min-width: 768px) {
      top: -100px;
      right: calc(50% - 500px);
    }
  }
}

.strate-data-smart-office {
  .o-macaron {
    top: -50px;
    right: calc(50% - 36px);
    @media (min-width: 768px) {
      top: -150px;
      right: calc(50% - 200px);
    }
  }
}