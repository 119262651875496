//Call Tailwind Style
//La 'base' couche est destinée à des éléments tels que les règles de réinitialisation ou les styles par défaut appliqués aux éléments HTML bruts.
@tailwind base;

//La 'components' couche est destinée aux styles basés sur des classes que vous souhaitez pouvoir remplacer par des utilitaires.
@tailwind components;

//La 'utilities' couche est destinée aux petites classes à usage unique qui doivent toujours avoir la priorité sur tout autre style.
@tailwind utilities;

//Variable général
$media-desktop : 768px;

//Call Import Style Custom
@import "variables/fonts";
@import "variables/colors";
@import "variables/button";
@import "variables/transitions";

@import "script/locomotiveScroll";
@import "component/navigation";
@import "component/homepageHeader";
@import "component/footer";
@import "component/macaron";
@import "component/modal";
@import "src/styles/component/swiper";

//

.loadingScreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: #1F2832;
  height: 100%;
  width: 100%;
}

[x-cloak] { display: none !important; }
html {
  @apply bg-black;
  @media screen and (min-width: $media-desktop) {
    will-change: transform, opacity;
  }
}
body {
  font-family: Poppins, sans-serif;
  font-weight: 400;
}
.o-scroll {
  overflow: hidden;
  max-width: 1980px;
  margin: 0 auto;
  will-change: transform, opacity;
}
.c-section {
  position: relative;
  border: 0px solid rgba(255,255,255,.1);
  max-width: 1980px;
  margin: 0 auto;
}
.o-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  //border: 1px solid pink;
}

//PAGE HOME
.strate-header {
  .o-image_wrapper {
    max-width: 1600px;
    margin: 0 auto;
    display: block;
    left: 0;
    right: 0;
    @media (max-width: 767px) {
      .o-image, img {
        object-fit: cover;
        height: 100vh;
      }
    }
  }
  .g-line {
    @apply block bg-white w-0.5;
  }
  .o-carousel {
    min-height: 100vh;
    background-image: url("../assets/images/images/IMAGES/MACBOOKPRO/Macbookpro2.png");
    background-size: cover;
    background-position: center center ;
    @media (min-width: 768px) {
      min-height: 585px;
    }
  }
  .swiper-datapureplayer {
    background-size: cover;
  }
}
.strate-datapureplayer {
  .o-image_wrapper {
    max-width: 1920px;
    margin: 0 auto;
    display: block;
    left: 0;
    right: 0;
    @media (max-width: 767px) {
      visibility: hidden;
    }
  }
  .g-line {
    @apply block bg-white w-12 h-1;
  }
  .o-carousel {
    background-image: url("../assets/images/images/IMAGES/MACBOOKPRO/Macbookpro2.png");
    background-size: cover;
    background-position: center center ;
    @media (max-width: 767px) {
      background: none;
    }
    @media (min-width: 768px) {
      min-height: 585px;
    }
  }
  .swiper-datapureplayer {
    background-size: cover;
  }
  @media (max-width: 767px) {
    .swiper-slide {
      min-height: 228px;
      max-width: 60%;
      min-width: 160px;
      border-radius: 10px;
    }
    .swiper-container {
      visibility: hidden;
    }
  }
}
.strate-happy {
  .logo-partenaire {
    figure {
      @apply flex justify-center items-center
    }
    img {
      max-height: 50px;
      max-width: 120px;
      width: 100%;
      height: auto;
      object-fit: cover;
      @media (min-width: 768px) {
        width: 100%;
        height: auto;
        max-height: 120px;
        max-width: 192px;
        object-fit: cover;
      }
    }
  }
}
.strate-makeithappen {
  .o-image_wrapper {
    max-width: 1920px;
    margin: 0 auto;
    img {
      max-height: 1000px;
    }
    &.image-1 {
      left: calc(50% + 15px);
      height: 75px;
      width: 75px;
      @media (min-width: 768px) {
        height: 198px ;
        width: 198px;
        img {
          height: 198px;
          width: 198px;
        }
      }
    }
    &.image-2 {
      img {
        object-fit: cover;
        max-height: 400px;
        opacity: .5;
        @media (min-width: 768px) {
          max-height: 980px;
        }
      }
    }
    &.image-3 {
      display: none;
      position: absolute;
      top: 300px;
      z-index: -1;
      .o-image {
        display: flex;
        justify-content: flex-end;
      }
      @media (min-width: 768px){
        display: block;
      }
    }
  }
  .c-item {
    &.c-item-left {
      .blur {
        top: -50px;
        left: -50px;
        @media screen and (min-width: $media-desktop){
          height: 400px;
          width: 400px;
          top: -125px;
          left: -125px;
        }
      }
    }
    &.c-item-right{
      .blur {
        top: -50px;
        right: -50px;
        @media screen and (min-width: $media-desktop){
          height: 400px;
          width: 400px;
          top: -125px;
          right: -125px;
        }
      }
    }
    h3 {
      @apply flex flex-col
    }
    .g-line {
      @apply bg-white;
      height: 1px;
    }
  }
}
.strate-more-than {
  h4 {
    @apply text-[46px];
    @media (min-width: 768px) {
      @apply text-[164px];
      line-height: 115px;
    }
  }
  .image-1 {
    width: 1280px;
    top: -400px;
    left: 0;
    right: 0;
    margin:auto;
    @media (min-width: 768px) {
      width: 1920px;
      top: -1000px;
    }
  }
  &.alt {
    h4 {
      @apply text-[1.625rem];
      @media (min-width: 768px) {
        @apply text-[5.75rem] whitespace-nowrap;
        line-height: 115px;
      }
    }
  }
}
.strate-techno-addict {
  .swiper-techno-addict {
    background-size: cover;
  }
  @media (max-width: 767px) {
    .swiper-slide {
      min-height: 240px;
      max-width: 166px;
      border-radius: 10px;
    }
    .swiper-container {
      visibility: hidden;
    }
  }
}
.strate-data-family {
  .logo-partenaire {
    figure {
      @apply flex justify-center items-center;
    }
    img {
      max-height: 50px;
      max-width: 120px;
      width: 100%;
      height: auto;
      object-fit: cover;
      @media (min-width: 768px) {
        width: 100%;
        height: auto;
        max-height: 120px;
        max-width: 192px;
        object-fit: cover;
      }
    }
  }
  .o-image_wrapper {
    max-width: 1920px;
    margin: 0 auto;
    img {
      max-height: 1000px;
    }
    &.image-1 {
      top: -50px;
      left: 70%;
      height: 75px;
      width: 75px;
    }
  }
  @media (min-width: 768px) {
    //min-height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    .o-image_wrapper {
      &.image-1 {
        top: -150px;
        height: 198px;
        width: 198px;
        img {
          height: 198px;
          width: 198px;
        }
      }
    }
  }
}
.strate-data-smart-office {
  .o-image_wrapper {
    max-width: 1920px;
    margin: 0 auto;
    img {
      max-height: 1000px;
    }
    &.image-1 {
      top: -70px;
      left: calc(50% - 37px);
      height: 75px;
      width: 75px;
      @media (min-width: 768px) {
        top: -150px;
        left: calc(50% + 100px);
        height: 198px;
        width: 198px;
        img {
          height: 198px;
          width: 198px;
        }
      }
    }
    &.image-2 {
      left: 0;
      right: 0;
      .o-image {
        display: flex;
        justify-content: center;
      }
      img {
        height: 350px;
        object-fit: cover;
        @media (min-width: 768px) {
          height: auto;
          max-width: 1080px;
        }
      }
    }
  }
  h3 {
    @apply text-xs;
    @media (min-width: 768px) {
      @apply text-2xl;
    }
  }
  h4 {
    @apply text-2xl;
    @media (min-width: 768px) {
      @apply text-7xl;
    }
  }
  p {
    @apply text-xs;
    @media (min-width: 768px) {
      @apply text-xl;
    }
  }
  @media (min-width:768px) {
    min-height: 900px;
  }
}
.strate-data-lovers {
  .c-carousel-scroll {
    display: flex;
    width: 100vw;
    height: auto;
    z-index: 30;
    position: relative;
    overflow: auto;
  }
  .c-card {
    min-height: 240px;
  }
  .image-1 {
    width: 100vw;
    top: -125vh;
    left: 0;
    height: 280vh;
    right: 0;
    margin: auto;
    .o-image {
      height: 100%;
      display: flex;
      width: 100%;
      flex: 1;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
  @media (min-width: 768px) {
    //min-height: 100vh;
    .c-carousel-scroll {
      max-width: 1920px;
      width: 100vw;
      margin: 0 auto;
      overflow: hidden;
    }
    .c-card {
      min-height: 500px;
    }
    .image-1 {
      //height: 400vh;
      //overflow: hidden;
      //width: 100%;
      //top: -200vh;
      //max-width: 1920px;
      height: 4500px;
      overflow: hidden;
      width: 100%;
      top: -2200px;
      max-width: 1920px;
      .o-image {
        height: 100%;
        display: flex;
        width: 100%;
        flex: 1;
        img {
          object-fit: fill;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

//PAGE PHILOSOPHIE
.bg-grid {
  background-image: url("../../src/assets/images/11-philosophie/Grid2.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  background-color: #000;
  @media (min-width: 768px) {
    background-size: 1380px;
  }
}
.bg-gradient-philo {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  &.top {
    background-image: url("../../src/assets/images/11-philosophie/GRADIENT-BG-TOP.png");
  }

  &.bottom {
    background-image: url("../../src/assets/images/11-philosophie/GRADIENT-BG-BOTTOM.png");
    @media (min-width: 768px) {
      background-size: 1980px 90%;
    }
  }
}
.bg-header-fixed {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  @media (min-width: 768px) {
    position: fixed;
    display: block;
  }

  &__philosophie {
    background-image: url("../../src/assets/images/11-philosophie/EULIDIA-7.jpg");
    //background-size: contain;
    height: 800px;
    width: 100%;
    top: 0px;
    z-index: 0;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      height: 600px;
      width: 100%;
      z-index: 1;
      background: rgb(255, 255, 59);
      background: radial-gradient(circle, rgba(255,255,59,0.5) -30%, rgba(0, 0, 0, 1) 100%);
      box-shadow: inset 0 -100px 200px 20px #000;
    }

    @media (min-width: 768px) {
      background-size: cover;
      height: 850px;
      width: 100%;
      top: 0;
      &:after {
        top: 0;
        height: 850px;
        width: 100%;
        max-width: 1980px;
      }
    }
  }
  &__data-stories {
    background-image: url("../../src/assets/images/06-datastory/Header.png");
    background-size: cover;
    background-position: center;
    height: 425px;
    width: 100%;
    max-width: 1980px;
    top: 0px;
    z-index: 0;
    @media (min-width: 768px) {
      background-size: cover;
      //height: 100vh;
      //min-height: 900px;
      min-height: 850px;
      height: 850px;
      width: 100%;
      top: 0;
    }
  }
  &__data-stories-detail {
    background-image: url("../../src/assets/images/07-datastory-detail/header.jpg");
    background-size: cover;
    background-position: center;
    height: 425px;
    width: 100%;
    max-width: 1980px;
    top: 0px;
    z-index: 0;
    &.santeclair            { background-image: url("../../src/assets/images/illustrations/stories/0.jpg"); }
    &.otis {}
    &.resto-du-coeur        { background-image: url("../../src/assets/images/illustrations/stories/10.jpg"); }
    &.abeilles-assurances   { background-image: url("../../src/assets/images/illustrations/stories/25.jpg"); }
    &.pernod-ricard         { background-image: url("../../src/assets/images/illustrations/stories/1.jpg"); }
    &.givenchy              { background-image: url("../../src/assets/images/illustrations/stories/17.jpg"); }
    &.hellobank             { background-image: url("../../src/assets/images/illustrations/stories/16.jpg"); }
    &.hellobank-2           { background-image: url("../../src/assets/images/illustrations/stories/15.jpg"); }
    &.hellobank-3           { background-image: url("../../src/assets/images/illustrations/stories/23.jpg"); }
    &.lvmh                  { background-image: url("../../src/assets/images/illustrations/stories/13.jpg"); }
    &.lvmh-2                { background-image: url("../../src/assets/images/illustrations/stories/4.jpg"); }
    &.lvmh-3                { background-image: url("../../src/assets/images/illustrations/stories/5.jpg"); }
    &.simply-market         { background-image: url("../../src/assets/images/illustrations/stories/20.jpg"); }
    &.casino                { background-image: url("../../src/assets/images/illustrations/stories/21.jpg"); }
    &.ccr                   { background-image: url("../../src/assets/images/illustrations/stories/14.jpg"); }
    &.grdf                  { background-image: url("../../src/assets/images/illustrations/stories/7.jpg"); }
    &.bouygues              { background-image: url("../../src/assets/images/illustrations/stories/3.jpg"); }
    &.bouygues-2            { background-image: url("../../src/assets/images/illustrations/stories/19.jpg"); }
    &.bouygues-construction { background-image: url("../../src/assets/images/illustrations/stories/24.jpg"); }
    &.bouygues-construction-2 { background-image: url("../../src/assets/images/illustrations/stories/12.jpg"); }
    &.groupe-beaumanoir     { background-image: url("../../src/assets/images/illustrations/stories/6.jpg"); }
    &.effy                  { background-image: url("../../src/assets/images/illustrations/stories/26.jpg"); }
    &.john-paul             { background-image: url("../../src/assets/images/illustrations/stories/18.jpg"); }
    &.cnr                   { background-image: url("../../src/assets/images/illustrations/stories/8.jpg"); }
    &.cnr-2                 { background-image: url("../../src/assets/images/illustrations/stories/2.jpg"); }
    &.carglass              { background-image: url("../../src/assets/images/illustrations/stories/22.jpg"); }
    &.karavel               { background-image: url("../../src/assets/images/illustrations/stories/11.jpg"); }
    &.fdj                   { background-image: url("../../src/assets/images/illustrations/stories/9.jpg"); }

    @media (min-width: 768px) {
      background-size: cover;
      height: 100vh;
      min-height: 900px;
      width: 100%;
      top: 0;
    }
  }
  &__eulidia-institute {
    background-image: url("../../src/assets/images/08-eulidia-institute/header-eulidia-institute.png");
    background-size: cover;
    background-position: center;
    height: 425px;
    width: 100%;
    max-width: 1980px;
    top: 0px;
    z-index: 0;
    @media (min-width: 768px) {
      background-size: cover;
      height: 100vh;
      min-height: 900px;
      width: 100%;
      top: 0;
    }
  }
  &__partenaires {
    background-image: url("../../src/assets/images/10-partenaires/header-partenaires.png");
    background-size: cover;
    background-position: center;
    height: 425px;
    width: 100%;
    max-width: 1980px;
    top: 0px;
    z-index: 0;
    @media (min-width: 768px) {
      background-size: cover;
      //height: 100vh;
      //min-height: 900px;
      min-height: 1000px;
      height: 1000px;
      width: 100%;
      top: calc((-10vh + 12.5%) * 10 );
      right: 0px;
    }
  }
  &__carriere {
    background-image: url("../../src/assets/images/09-carriere/header-carriere.jpg");
    background-size: cover;
    background-position: center;
    height: 425px;
    width: 100%;
    max-width: 1980px;
    top: 0px;
    z-index: 0;
    @media (min-width: 768px) {
      background-size: cover;
      //height: 100vh;
      //min-height: 900px;
      min-height: 1000px;
      height: 1000px;
      width: 100%;
      top: calc((-10vh + 12.5%) * 10 );
      right: -50px;
    }
  }
  &__contact {
    background-image: url("../../src/assets/images/12-contact/header-contact.jpg");
    background-size: cover;
    background-position: center;
    height: 425px;
    width: 100%;
    max-width: 1980px;
    top: 0px;
    z-index: 0;
    opacity: .5;
    @media (min-width: 768px) {
      background-size: cover;
      height: 600px;
      min-height: 600px;
      max-height: 600px;
      width: 100%;
      top: calc((-10vh + 13%) * 10 );
      opacity: 1;
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100vw;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      }
    }
  }
}
.strate-header-philosophie {
  @apply bg-transparent;
  .strate-header-philosophie__top {
    margin-top: 60px;
    min-height: 400px;
    max-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width:768px) {
      margin-top: 90px;
      min-height: 850px;
      max-height: 850px;
    }
  }
  h2 {
    @apply font-secondary font-medium leading-none tracking-wider text-center;
  }
  h3 {
    @apply font-secondary text-[12px] md:text-[30px] font-medium leading-normal tracking-wider text-center uppercase mt-4 md:mt-8;
  }
  p {
    @apply font-primary text-[26px] md:text-[50px] font-bold leading-normal tracking-tighter text-center mt-4 md:mt-8;

  }
  .image-placement-1 {
    @apply flex items-center justify-center h-[75px] md:h-[200px] mb-4 ;
  }
  .image-placement-2 {
    @apply flex items-center justify-center mt-4 md:mt-8;
    width: 65px;
    height: auto;
    margin: 20px auto;
    @media (min-width:768px) {
      width: 110px;
      height: auto;
    }
  }
}
.strate-conviction {
  .o-container {
    .o-paragraphe_wrapper {
      justify-content: center;
      > * {
        @apply mb-4;
      }
      ul {
        p {
          @apply grid grid-flow-col auto-cols-max grid-rows-1 gap-2 items-center mb-3;
        }
        .icon, .icon img {
          @apply h-6 w-6;
        }
        .text {
          @apply font-primary font-extrabold text-sm
        }
      }
    }
    .o-image_wrapper {
      width: 100%;
    }
    .gradient {
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -100px;
        top: -100px;
        z-index: -1;
        height: 300px;
        width: 100%;
        background-size: 90%;
        background-repeat: no-repeat;
      }
    }
    .gradient-blue {
      &:before {
        background-image: url("../assets/images/gradient/blured-blue.png");
      }
    }
    .gradient-yellow {
      &:before {
        background-image: url("../assets/images/gradient/bured-yellow.png");
      }
    }
  }
  &.conviction-1 {
    .o-image_wrapper {}
    .image {}
    .image-placement-1 {
      @apply w-full m-auto flex items-center justify-center;
      display: none;
    }
    @media (min-width:768px) {
      .o-image_wrapper {}
      .image {
        min-width: 768px;
        width: 50vw;
        max-width: 990px;
        height: auto;
        object-fit: contain;
      }
      .image-placement-1 {
        display: flex;
      }
    }
  }
  &.conviction-2 {
    .o-image_wrapper {}
    .image {}
    .image-placement-1 {
      @apply w-full m-auto flex items-center justify-start;
      top: -100px;
      display: none;
    }
    @media (min-width:768px) {
      .o-image_wrapper {}
      .image {
        min-width: 768px;
        width: 50vw;
        max-width: 990px;
        height: auto;
        object-fit: contain;
      }
      .image-placement-1 {
        display: flex;
      }
    }
  }
  &.conviction-3 {
    .o-image_wrapper {}
    .image {
      width: 100%;
    }
    .image-placement-1 {
      @apply w-full m-auto flex items-center justify-start;
      top: -175px;
      display: none;
    }
    .image-placement-2 {
      @apply w-full m-auto flex items-center justify-start;
      bottom: -50px;
      display: none;
    }

    @media (min-width:768px) {
      .o-image_wrapper {}
      .image {
        min-width: 768px;
        width: 50vw;
        max-width: 990px;
        height: auto;
        object-fit: contain;
      }
      .image-placement-1 {
        display: flex;
        height: 280px;
      }
      .image-placement-2 {
        display: flex;
        height: 200px;
      }
    }
  }
  &.conviction-4 {
    .o-image_wrapper {}
    .image {
      width: 100%;
    }
    .image-placement-1 {
      @apply w-full m-auto flex items-center justify-end;
      right: -100px;
      top: -50px;
      display: none;
    }
    @media (min-width:768px) {
      .o-image_wrapper {}
      .image {
        min-width: 768px;
        width: 50vw;
        max-width: 1200px;
        height: auto;
        object-fit: contain;
      }
      .image-placement-1 {
        display: flex;
      }
    }
  }
  &.conviction-5 {
    .o-paragraphe_wrapper {
      * {
        text-align: center;
      }
    }
    .o-image_wrapper {}
    .image {
      width: 100%;
    }
    .image-placement-1 {
      @apply w-full m-auto flex items-center justify-center;
      top: -5rem;
      display: none;
      //border: 1px solid red;
    }
    .image-placement-2 {
      @apply w-full m-auto flex items-center justify-start;
      top: -8rem;
      display: none;
    }
    @media (max-width:767px) {
      .gradient:before {
        left: 0;
      }
    }
    @media (min-width:768px) {
      .o-paragraphe_wrapper {
        * {
          text-align: left;
        }
      }
      .o-image_wrapper {
        right: -5rem;
      }
      .image {
        min-width: 768px;
        width: 50vw;
        max-width: 1200px;
        height: auto;
        max-height: 670px;
        object-fit: contain;
      }
      .image-placement-1,
      .image-placement-2 {
        display: flex;
      }
    }
  }
  @media (min-width:768px) {
    .o-container {
      min-height: 745px;
      .o-paragraphe_wrapper {
        justify-content: center;
        > * {
          @apply mb-8;
        }
        ul {
          p {
            @apply grid grid-flow-col auto-cols-max grid-rows-1 gap-4 items-center mb-6;
          }
          .icon, .icon img {
            @apply h-10 w-10;
          }
          .text {
            @apply font-primary font-extrabold text-2xl
          }
        }
      }
      .o-image_wrapper {
        width: 60vw;
        max-width: 1200px;
      }
      .gradient {
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: -120px;
          z-index: -1;
          height: 500px;
          width: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          //background-image: url("../../src/assets/images/gradient/bured-yellow.png");
        }
      }
      .gradient-blue {
        &:before {
          background-image: url("../assets/images/gradient/blured-blue.png");
        }
      }
      .gradient-yellow {
        &:before {
          background-image: url("../assets/images/gradient/bured-yellow.png");
        }
      }
    }
  }
}
.strate-conviction-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  &.left-image {
    .o-image_wrapper {
      align-items: center;

      .image {
        position: relative;
        right: 40px;
        left: -10%;
      }
    }
  }
  &.right-image {
    .o-image_wrapper {
      align-items: center;

      .image {
        position: relative;
        right: -10%;
        left: 40px;
      }
    }
  }
  .image {
    @apply rounded-[2rem];
    overflow: hidden;
    border: 8px solid black;
    background: black;
    box-shadow:
        0 0 0px 1px gray,
        0 0 0px 2px lightgrey,
        0 0 0px 3px gray,
        0px 0px 80px -10px #000;
    img {
      @apply rounded-[1rem];
      object-fit: cover;
    }
  }

  @media (min-width: 768px) {
  &.left-image {
    .o-image_wrapper {
      align-items: center;

      .image {
        position: absolute;
        right: 6rem;
        //left: -100%;
        left: -25vw;
      }
    }
  }
  &.right-image {
    .o-image_wrapper {
      align-items: center;
      .image {
        position: absolute;
        //right: -100%;
        right: -25vw;
        left: 6rem;
      }
    }
  }
  .image {
    @apply rounded-[4rem];
    max-height: 725px;
    overflow: hidden;
    border: 28px solid black;
    background: black;
    box-shadow:
        0 0 0px 2px gray,
        0 0 2px 4px lightgrey,
        0 0 0px 6px gray,
        0px 0px 80px -10px #000;
      img {
        @apply rounded-[3rem];
        min-height: 65vh;
        max-height: 725px;
      }
    }
  }
}
.strate-conviction-list {
  box-shadow: 0 0 50px 50px rgba(0,0,0,1) ;
  .group-item {
    @apply grid grid-cols-1 border-t py-10 border-white/[.25];
    grid-template-rows: 80px auto;
  }
  .group-item-left {
    @apply grid gap-x-0;
    grid-template-rows: 80px;
    grid-template-columns: 40px 1fr;
    align-items: center;
  }
  .group-item-right {
    @apply flex flex-row items-start;
  }
  .number {
    @apply grid grid-rows-2;
    width: 40px;
    grid-template-rows: 30px 30px;
    p {
      @apply font-tertiary font-black font-outline text-2xl uppercase leading-none whitespace-nowrap relative;
    }
    img {
      width: 25px;
      height: auto;
    }
  }
  .title {
    @apply grid grid-flow-col;
    grid-template-rows: 30px 30px;
    align-items: center;
    h3 {
      @apply font-primary font-extrabold text-2xl leading-none tracking-tighter;
    }
  }
  .paragraph {}

  @media (min-width: 768px) {
    box-shadow: 0 0 150px 150px rgba(0,0,0,1) ;
    .group-item {
      @apply grid grid-rows-1 grid-cols-2 border-t py-10 border-white/[.25];
    }
    .group-item-left {
      @apply grid grid-rows-1 gap-x-0;
      grid-template-columns: 80px 1fr;
      align-items: center;
    }
    .group-item-right {
      @apply flex flex-row items-center;
    }
    .number {
      grid-template-rows: 60px 60px;
      p {
        @apply text-5xl;
      }
      img {
        width: 45px;
      }
    }
    .title {
      grid-template-rows: 60px 60px;
      h3 {
        @apply text-6xl;
      }
    }
  }
}
.strate-team {
  .o-image_wrapper {
    top: 300px;
    .o-image {
      img {
        height: 2750px;
        width:  2750px;
        object-fit: cover;
      }
    }
  }
  .o-container {
    .o-paragraphe_wrapper {
      justify-content: center;

      > * {
        @apply mb-4;
      }

      ul {
        p {
          @apply grid grid-flow-col auto-cols-max grid-rows-1 gap-2 items-center mb-3;
        }

        .icon, .icon img {
          @apply h-6 w-6;
        }

        .text {
          @apply font-primary font-extrabold text-sm
        }
      }
    }
    .gradient {
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -100px;
        top: -100px;
        z-index: -1;
        height: 300px;
        width: 100%;
        background-size: 90%;
        background-repeat: no-repeat;
        background-image: url("../../src/assets/images/gradient/bured-yellow.png");
      }
    }
  }
  .image-placement-1 {
    @apply w-full m-auto flex items-center justify-start;
    top: -5rem;
    display: none;
  }
  @media (min-width: 768px) {
    //overflow: hidden;
    .group-card {
      display: flex;
      column-gap: 5.625rem;
    }
    .o-image_wrapper {
      top: 0;
      .o-image {
        img {
          width:  1980px;
          object-fit: inherit;
          height: 2900px;
        }
      }
    }
    .o-container {
      .gradient {
        &:before {
          display: none;
        }
      }
    }
    .content {
      height: auto;
    }
    .image-placement-1 {
      display: flex;
    }
  }
}

//PAGE THEME HEADER
.strate-theme-header {
  .o-image_wrapper {
    max-width: 1980px;
    margin: 0 auto;
    display: block;
    left: 0;
    right: 0;
    @media (max-width: 767px) {
      .o-image, img {
        object-fit: cover;
        height: 500px;
      }
    }
  }
  .g-line {
    @apply block bg-white w-full h-[1px];
  }
  .strate-theme-header__top {
    @apply md:pt-[150px];
    h2 {
      @apply font-secondary  font-medium;
    }
    h3 {
      @apply font-tertiary font-medium uppercase text-white font-semibold;
    }
  }
  .strate-theme-header__baseline {
    @apply text-center mt-32 mb-16;
    h2:nth-child(1) {
      @apply text-white text-[28px] md:text-[58px] tracking-wider
      md:absolute md:top-0 md:left-0;
      @media (min-width: 768px) {
        width: 200px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    h2:nth-child(2) {
      @apply font-outline text-[44px] md:text-[160px] tracking-wider;
    }
    h2:nth-child(3) {
      @apply text-white tracking-wider;
    }
    h3:nth-child(5) {
      @apply text-[10px]
      md:absolute md:top-0 md:right-0 md:text-[20px] ;
      letter-spacing: .25rem;
      font-style: oblique;
      @media (min-width: 768px) {
        letter-spacing: .45rem;
        width: 200px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .strate-theme-header__bottom {
    @apply relative;
    h4 {
      @apply font-tertiary font-black text-2xl text-white leading-none text-center z-10 mb-4 md:mb-16 md:text-[80px];
    }
    p {
      @apply font-tertiary font-normal text-sm text-white leading-normal text-center z-10 mb-4 md:mb-16 md:text-[37px] md:leading-tight;
    }
  }
}
.strate-header-strategy {
  .strate-theme-header__baseline {
    h2:nth-child(3) {
      @apply text-[40px] md:text-[160px];
    }
  }
}
.strate-header-transformation {
  .strate-theme-header__baseline {
    h2:nth-child(3) {
      @apply text-[26px] md:text-[90px];
    }
  }
}
.strate-header-delivery {
  .strate-theme-header__baseline {
    h2:nth-child(3) {
      @apply text-[40px] md:text-[160px];
    }
  }
}
.strate-header-technology {
  .strate-theme-header__baseline {
    h2:nth-child(3) {
      @apply text-[40px] md:text-[120px];
    }
  }
}

//PAGE THEME BLOCK
.strate-theme-block {
  @apply text-white;
  &.bg-ampoule {
    background-image: url("../../src/assets/images/images/IMAGES/AMPOULE/Ampoule-70.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .strate-theme-block__top {
    @apply flex flex-row justify-between mb-4 md:mb-8;
    > img {
      @apply w-[56px] h-auto md:w-[130px];
    }
    .icon {
      @apply h-[25px] w-[25px] md:h-[64px] md:w-[64px];
    }
    h3 {
      @apply font-primary font-extrabold text-2xl md:text-[70px] text-white leading-none uppercase tracking-tighter ;
    }
  }
  .strate-theme-block__title {
    @apply mb-4;
  }
  .strate-theme-block__list {
    @apply border-t;
    border-color: rgba(255,255,255,.2);
    li {
      @apply flex flex-row gap-4 border-b py-4 items-center;
      border-color: rgba(255,255,255,.2);
    }
    span {
      @apply font-tertiary font-black text-xl md:text-[50px] text-outline;
    }
    h5 {
      @apply font-primary font-extrabold text-sm md:text-[44px] tracking-tighter ;
    }
  }
  .strate-theme-block__steps {
    @apply flex flex-col gap-8 md:gap-8;
    h6 {
      @apply font-tertiary text-sm font-semibold leading-none md:text-[30px];
    }
    h3 {
      @apply font-tertiary text-2xl font-black leading-none md:text-[80px];
    }
    h4 {
      @apply font-tertiary text-2xl font-black leading-none md:text-[60px];
    }
    p {
      @apply font-tertiary text-sm font-normal leading-normal md:text-[22px] md:leading-tight md:pr-4;
      color: rgba(255, 255, 255, .5);
      @media screen and (min-width: $media-desktop) {
        color: rgba(255, 255, 255, .8);
      }
    }
    a {
      @apply md:w-[250px] text-center;
    }
  }
  .strate-theme-block__step__left {
    @apply flex flex-col gap-16 md:flex-row md:gap-12 md:items-center;
    > div {
      @apply md:w-1/2;
    }
    .strate-theme-block__step--content {
      @apply order-1;
    }
    .strate-theme-block__step--image {
      @apply order-2 md:w-[500px] md:h-[500px];
    }
  }
  .strate-theme-block__step__right {
    @apply flex flex-col gap-16 md:flex-row md:gap-12 md:items-center;
    > div {
      @apply md:w-1/2;
    }
    .strate-theme-block__step--content {
      @apply order-1 md:order-2;
    }
    .strate-theme-block__step--image {
      @apply  order-2 md:order-1 md:w-[500px] md:h-[500px];
    }
  }
  .strate-theme-block__step--content {
    @apply flex flex-col gap-4 md:gap-8 md:h-auto;
  }
  .strate-theme-block__step--image {
    position: relative;
    @media screen and (min-width: $media-desktop) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      margin: auto;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;
      opacity: .75;
      border-radius: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @media screen and (min-width: $media-desktop) {
        top: -25px;
        left: -25px;
        height: 550px;
        width: 550px;
      }
    }
  }
  &.strate-theme-block__technology {
    .strate-theme-block__step__left {
      @apply gap-0 md:gap-4;
      .strate-theme-block__step--image {
        @apply order-1 md:order-2 md:-right-2;
        img {
          @apply md:min-w-[950px] md:absolute md:left-0;
        }
      }
      .strate-theme-block__step--content {
        @apply order-2 md:order-1 text-center md:text-left;
        h3 {
          @apply md:text-[70px] font-primary font-extrabold tracking-tighter text-center md:text-left;
        }
        p {
          @apply md:text-[22px];
        }
      }
      .image-placement-1 {
        @apply md:absolute md:top-[-150px] md:-right-[17vw];
      }
    }
  }
}

//SECTION TECHNO HUB
.strate-techno-hub {
  @apply text-white;
  .icon {
    @apply w-[44px] md:w-[80px];
  }
  h6 {
    @apply font-tertiary text-sm font-semibold leading-none md:text-[30px];
  }
  h2 {
    @apply font-tertiary text-[12px] md:text-[30px] font-black tracking-tight leading-normal md:text-[22px];
  }
  h3 {
    @apply font-primary text-[30px] font-extrabold tracking-tighter leading-none md:text-[70px];
    &.gradient:before {
      top: 0;
    }
  }
  h4 {
    @apply flex flex-1 justify-center items-center font-tertiary border border-white rounded-full text-[8px] font-extrabold leading-none md:text-[18px]
    p-2 md:p-3;
  }
  p {
    @apply font-tertiary text-sm font-normal leading-normal md:text-[22px] md:pr-20 md:leading-tight;
    color: rgba(255, 255, 255, .5);
    @media screen and (min-width: $media-desktop) {
      color: rgba(255, 255, 255, .6);
    }
  }
  a {
    @apply md:w-[250px] text-center flex items-center gap-x-4 font-tertiary font-black text-sm md:text-[26px];
    .icon {
      @apply w-[8px] md:w-[14px];
    }
  }
  &__description {
    @apply flex flex-col gap-8;
  }
}

//SECTION DATA STORIES HUB
.header {
  &__data-stories {
    @apply text-white z-10;
    &__top {
      @apply flex items-center min-h-[425px] md:min-h-[850px] md:h-[850px] pt-[60px] md:pt-[0];
      h4 {
        @apply font-secondary font-normal uppercase text-xs md:text-[32px];
      }
      h3 {
        @apply font-primary font-extrabold text-[26px] md:text-[70px] tracking-tighter leading-none;
      }
      p  {
        @apply font-tertiary font-normal text-sm md:text-[22px] leading-normal md:leading-10;
      }
    }
  }
  &__contact {
    .o-container {
      width: 100%;
    }
    .header__data-stories__top {
      @apply flex items-center min-h-[425px] md:min-h-[600px] md:h-1/2 pt-[60px] md:pt-[0];
    }
  }
  &__mentionslegales {
    .o-container {
      width: 100%;
    }
    .header__data-stories__top {
      @apply flex items-center min-h-[425px] md:min-h-[600px] pt-[60px] md:py-32 ;
      height: auto;
      ul {
        @apply font-primary font-normal text-xs md:text-[16px] opacity-50 leading-10;
      }
      h4 {
        @apply font-secondary font-normal uppercase text-xs md:text-[32px];
      }
      h3 {
        @apply font-primary font-extrabold text-[26px] md:text-[40px] tracking-tighter leading-none;
      }
      p  {
        @apply font-tertiary font-normal text-sm md:text-[22px] leading-normal md:leading-10;
      }
    }
  }
}
.strate {
  &__data-stories {
    @apply relative text-white bg-black;
    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 10vw;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      background: rgb(0,0,0);
      background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 10vw;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
      background: rgb(0,0,0);
      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    }
    h3 {
      @apply font-primary text-[26px] font-extrabold tracking-tighter leading-none md:text-[70px];
      &.gradient:before {
        top: -50px;
      }
    }
    h4 {
      @apply flex flex-row justify-start items-center font-tertiary text-[12px] md:text-[30px] font-normal leading-none;
    }
    &__description {
      @apply flex flex-col gap-4;
    }

    //custom swiper
    .swiper-container {
      display: none;
      position: absolute;
      left: 0;
      bottom: 500px;
      right: 0;
      z-index: 100;
      @media (min-width: 768px) {
        display: block;
        bottom: 315px;
      }
    }
    .swiper-button-next.swiper-button-disabled,.swiper-button-prev.swiper-button-disabled {
      opacity: 0;
    }
    .swiper-button-prev {
      left: 2rem;
    }
    .swiper-button-next {
      right: 2rem;
    }

    //bg--color
    &.bg--green {
      background-image: url("../../src/assets/images/06-datastory/lights-green.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      @media (min-width:768px) {
        //background-size: cover;
        //background-size: 80vw;
        //background-position: 0% 80%;
        //background-size: 1200px;
        //background-position: 400px 0px;
        background-size: 1400px;
        background-position: 0% -3vw;
      }
    }
    &.bg--orange {
      background-image: url("../../src/assets/images/06-datastory/lights-orange.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      @media (min-width:768px) {
        //background-size: cover;
        //background-size: 80vw;
        //background-position: 0% 80%;
        background-size: 1400px;
        background-position: 0% -3vw;
      }
    }
    &.bg--blue {
      background-image: url("../../src/assets/images/06-datastory/lights-blue.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      @media (min-width:768px) {
        //background-size: cover;
        //background-size: 80vw;
        //background-position: 0% 80%;
        background-size: 1400px;
        background-position: 0% -3vw;
      }
    }
    &.bg--pink {
      background-image: url("../../src/assets/images/06-datastory/lights-pink.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      @media (min-width:768px) {
        //background-size: cover;
        //background-size: 80vw;
        //background-position: 0% 80%;
        background-size: 1400px;
        background-position: 0% -3vw;
      }
    }
    &.bg--yellow {
      background-image: url("../../src/assets/images/06-datastory/lights-yellow.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      @media (min-width:768px) {
        //background-size: cover;
        //background-size: 80vw;
        //background-position: 0% 80%;
        background-size: 1400px;
        background-position: 0% -3vw;
      }
    }
  }
  &__data-stories--footer {
    @apply bg-black;
    .o-macaron {
      top: -25px;
      right: calc(50% - 36px);
      @media (min-width: 768px) {
        top: -25px;
        right: calc(50% - 400px);
      }
    }
    .o-image_wrapper {
      max-width: 1980px;
      margin: 0 auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
        img {
          height: 350px;
          object-fit: cover;
          @media (min-width: 768px) {
            height: 700px;
            width: 100%;
            object-fit: fill;
          }
        }
    }

    h3 {
      @apply text-xs;
      @media (min-width: 768px) {
        @apply text-2xl;
      }
    }
    h4 {
      @apply text-2xl;
      @media (min-width: 768px) {
        @apply text-7xl;
      }
    }
    p {
      @apply text-xs;
      @media (min-width: 768px) {
        @apply text-xl leading-relaxed;
      }
    }
    @media (min-width:768px) {
      .o-container {
        display: flex;
        align-items: center;
        min-height: 700px;
      }
    }
  }
}

//SECTION DATA STORIES DETAILS
.header {
  &__data-stories-detail {
    @apply text-white z-10;
    &__top {
      @apply flex items-center min-h-[425px] md:min-h-[750px] pt-[60px] md:pt-[0] w-full;
      h4 {
        @apply font-secondary font-normal uppercase text-xs md:text-[32px];
      }
      h3 {
        @apply font-primary font-extrabold text-[26px] md:text-[70px] tracking-tighter leading-none;
      }
      p  {
        @apply font-tertiary font-normal text-sm md:text-[22px] leading-normal md:leading-10;
      }
      h5 {
        @apply flex flex-1 justify-center items-center font-tertiary border border-white rounded-full text-[8px] font-extrabold leading-none md:text-[18px]
        p-2 md:p-3 text-center;
      }
    }
    &+ .strate__data-stories-detail {
      box-shadow: 0 0px 100px 100px black;
      @media (min-width: 768px){
        box-shadow: 0 -100px 350px 150px rgba(0,0,0,1);
      }
    }
  }
}
.strate {
  &__data-stories-detail {
    @apply relative text-white bg-black;
    .image-1 {
      z-index: -1;
      bottom: 0;
    }
    .o-macaron {
      right: 40px;
      @media (min-width: 768px) {
        right: 0;
      }
    }
    h3 {
           @apply font-primary text-[26px] font-extrabold tracking-tighter leading-none md:text-[70px];
           &.gradient:before {
             top: -20px;
           }
         }
    h4 {
      @apply flex flex-row justify-start items-center font-tertiary text-[12px] md:text-[30px] font-normal leading-none;
    }
    &__description {
      @apply flex flex-col gap-4;
    }
    .strate-theme-block__title {
      h3 {
        &.gradient:before {
          top: -40px;
        }
      }
      @media (min-width: 768px) {
        h3 {
          &.gradient:before {
            top: -130px;
          }
        }
      }
    }
    &.strate-theme-block.bg-ampoule {
      @media (min-width: 768px) {
        background-position: 60% center !important;
      }
    }
    .strate-theme-block__list h5 {
      @apply font-primary font-normal;
    }
    .basic-content {
      h3 {
        @apply font-primary text-[26px] font-extrabold tracking-tighter leading-none md:text-[70px];
        &.gradient:before {
          top: -40px;
        }
      }
      h4 {
        @apply flex flex-row justify-start items-center font-tertiary text-[12px] md:text-[30px] font-normal leading-none;
      }
      p {
        @apply font-tertiary text-sm;
      }
      @media (min-width: 768px) {
        h3 {
          &.gradient:before {
            top: -130px;
          }
        }
        p {
          @apply font-tertiary text-xl;
        }
      }
    }

    //custom swiper
    .swiper-container {
      display: none;
      position: absolute;
      left: 0;
      bottom: 500px;
      right: 0;
      z-index: 100;
      @media (min-width: 768px) {
        display: block;
      }
    }
    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
      opacity: 0;
    }
    .swiper-button-prev {
      left: 2rem;
    }
    .swiper-button-next {
      right: 2rem;
    }
  }
}

.strate__philosophie {
  .manifesto .intro .line .content span {
    @apply font-bold tracking-tighter text-[1rem] md:text-[3.125rem] md:leading-[4.2rem];
  }
}

//SECTION EULIDIA INSTITUTE
.strate__eulidia-insitute {
  &__formation {
    @apply flex flex-col gap-4 relative text-white bg-black;
    .tag {
      @apply flex flex-col gap-4;
      h3 {
        @apply font-primary text-white text-[26px] font-extrabold tracking-tighter leading-none md:text-[70px];
        &.gradient:before {
          top: -50px;
        }
      }
      h4 {
        @apply flex flex-row justify-start items-center font-tertiary text-white text-[12px] md:text-[30px] font-normal leading-none;
      }
      p {
        @apply font-bold tracking-tighter text-[1.625rem] md:text-[3.125rem];
      }
    }
    .o-image {
      img {
        @apply max-h-[56px] md:max-h-max ;
      }
    }
    .cards {
      @apply flex flex-col md:flex-row gap-8 md:gap-16;
    }
    .cta {
      position: relative;
      @media (min-width: 768px) {
        position: absolute;
        bottom: 38rem;
        right: 0;
      }
    }
    &.bg--purple {
      background-image: url("../../src/assets/images/08-eulidia-institute/lights-purple.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      @media (min-width:768px) {
        background-size: cover;
        background-position: 0% 80%;
      }
    }
  }
  &__contact {
    .modal {
      position: relative;
      top: inherit;
      .modal-container {
        min-height: inherit;
        height: auto;
      }
      .modal-inner {
        padding: 0;
        margin-right: 0;
        max-width: inherit;
      }
    }
    .o-image {
      @apply bottom-0 md:bottom-0;
    }
  }
  .manifesto .intro .line .content span {
    @apply font-bold tracking-tighter text-[1rem] md:text-[3.125rem] md:leading-tight;

  }
}

//SECTION PARTENAIRES
.strate__partenaires {
  &__formation {
    @apply flex flex-col gap-4 relative text-white bg-black;
    .tag {
      @apply flex flex-col gap-4 z-[2];
      h3 {
        @apply font-primary text-white text-[26px] font-extrabold tracking-tighter leading-none md:text-[70px];
        &.gradient:before {
          top: -50px;
        }
        &.gradient.gradient-color-xs:before {
          @apply top-[-150%] left-[-50px] md:top-[-150px];
        }
      }
      h4 {
        @apply flex flex-row justify-start items-center font-tertiary text-white text-[12px] md:text-[30px] font-normal leading-none;
      }
      p {
        @apply font-bold tracking-tighter text-[1.625rem] md:text-[3.125rem];
      }
      .o-macaron {
        position: absolute;
        right: 0;
        top: -100%;
      }
    }
    .o-image {
      img {
        @apply max-h-[56px] md:max-h-max ;
      }
    }
    .cards {
      @apply flex flex-col md:flex-row gap-8 md:gap-16;
    }
    .cta {
      position: relative;
      @media (min-width: 768px) {
        position: absolute;
        bottom: 44rem;
        right: 0;
      }
    }
    &.bg--purple .title {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        display: block;
        z-index: -1;
        background-image: url("../../src/assets/images/06-datastory/lights-purple.png");
        background-size: cover;
        height: 500px;
        width: 500px;
        top: -200px;
        left: -50px;
        background-repeat: no-repeat;
        background-position: top;
        @media (min-width:768px) {
          background-size: cover;
          height: 500px;
          width: 100%;
          top: -100%;
          left: 0;
        }
      }
    }
    &.bg--green .title {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        display: block;
        z-index: -1;
        background-image: url("../../src/assets/images/06-datastory/lights-green.png");
        background-size: cover;
        height: 500px;
        width: 500px;
        top: -200px;
        left: -50px;
        background-repeat: no-repeat;
        background-position: top;
        @media (min-width:768px) {
          background-size: cover;
          height: 500px;
          width: 100%;
          top: -100%;
          left: 0;
        }
      }
    }
  }
  &__contact {
    .modal {
      position: relative;
      top: inherit;
      .modal-container {
        min-height: inherit;
        height: auto;
      }
      .modal-inner {
        padding: 0;
        margin-right: 0;
        max-width: inherit;
      }
    }
    .o-image {
      @apply bottom-0;
    }
  }
  .swiper {
    overflow: visible;
    z-index: 2;
  }
  .swiper-slide {
    width: 240px;
  }
  .manifesto .intro .line .content span {
    @apply font-bold tracking-tighter text-[1rem] md:text-[3.125rem] md:leading-tight;
  }
  @media (min-width: 768px) {
    .swiper-slide {
      width: 465px;
    }
  }
}

//SECTION CARRIERE
.strate__carriere {
  &__formation {
    @apply flex flex-col gap-4 relative text-white bg-black;
    .tag {
      @apply flex flex-col gap-4;
      h3 {
        @apply font-primary text-white text-[26px] font-extrabold tracking-tighter leading-none md:text-[70px];
        &.gradient:before {
          top: -50px;
        }
      }
      h4 {
        @apply flex flex-row justify-start items-center font-tertiary text-white text-[12px] md:text-[30px] font-normal leading-none;
      }
      p {
        @apply font-bold tracking-tighter text-[1.625rem] md:text-[4.375rem] md:leading-tight;
      }
    }
    .o-image {
      img {
        @apply max-h-[56px] md:max-h-max ;
      }
    }
    .cards {
      @apply flex flex-col md:flex-row gap-8 md:gap-16;
    }
    .cta {
      position: relative;
      @media (min-width: 768px) {
        position: absolute;
        bottom: 44rem;
        right: 0;
      }
    }
    &.bg--purple {
      background-image: url("../../src/assets/images/08-eulidia-institute/lights-purple.jpg");
      background-size: 1200px;
      background-repeat: no-repeat;
      background-position: -10rem bottom;
      @media (min-width:768px) {
        background-size: 100% 95%;
        background-position: 0% 80%;
      }
    }
  }
  .list-primary--item {
    @apply gap-0;
  }
  .list-primary--text {
    @apply font-tertiary;
  }
}
.strate__swiper--03 {
  @apply bg-black text-white;
  .head {
    @apply py-[1.5rem];
    position: relative;
    height: 20.3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    .gradient:before {
      width: 100% ;
      top: -50% ;
      left: -25% ;
      height: 20.3rem;
      background-size: cover;
    }
    .image-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      overflow: hidden;
      img {
        display: flex;
        height: 20.3rem;
        width: 100%;
        object-fit: cover;
      }
    }
    .number {
      @apply font-tertiary font-black text-[1.875rem] leading-none;
    }
    .title {
      @apply relative font-primary font-extrabold text-[1.625rem] leading-none tracking-tighter;
      &.gradient {
        position: relative;
        &:before {
          top: -75px;
          left: -45px;
          width: 200px;
          height: 200px;
        }
      }
    }
    .descr {
      @apply font-tertiary font-bold text-[0.813rem] leading-none;
    }
    .contain {
      @apply flex flex-col gap-y-3;
    }
    .image-placement-1 {
      height: 56px;
      width:  56px;
      position: absolute;
      right: 2rem;
      top: -2rem;
    }
  }
  .body {
    @apply flex flex-col gap-y-[2.25rem];
    position: relative;
    .citation {
      @apply font-primary font-bold text-[1.625rem] leading-none tracking-tighter relative;
    }
    .list-descr {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .title {
      @apply font-tertiary font-black text-[1.375rem] leading-none;
    }
    .list-group {
      display: flex;
      flex-direction: column;
      gap: .3rem;
    }
    .list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
    .icon {
      @apply w-[0.875rem] h-[0.875rem];
    }
    .text {
      @apply font-tertiary font-semibold text-[0.875rem] leading-normal;
    }
    .btn {
      margin: 0 auto;
    }
  }
  .swiper {
    overflow: visible;
  }
  .swiper-container {
    display: none;
  }
  .swiper-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .swiper-slide {
    display: flex;
    flex-direction: column;
    width: 100%!important;
    gap: 2rem;
  }
  @media screen and (min-width: $media-desktop) {
    .head {
      flex: 1;
      height: 48rem;
      max-width: 510px!important;
      position: relative;
      
      > .gradient:before {
        position: absolute;
        width: 500px;
        top: -500px;
        left: 140px;
        height: 500px;
        background-size: cover;
        z-index: -1;
      }
      .image-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        overflow: hidden;
        img {
          display: flex;
          height: 48rem;
          width: 100%;
          object-fit: cover;
        }
      }
      .number {
        @apply text-[3.75rem];
      }
      .title {
        @apply text-[3.75rem];
        &.gradient:before {
          top: -250px;
          left: -120px;
          width: 600px;
          height: 600px;
        }
      }
      .descr {
        @apply text-[1.375rem];
      }
      .contain {
        position: relative;
        bottom: -3rem;
      }
      .image-placement-1 {
        height: 120px;
        width:  120px;
        position: relative;
        right: 0rem;
        top: 2rem;
      }
    }
    .body {
      @apply gap-y-[1.875rem];
      flex: 1;
      justify-content: flex-start;
      .citation {
        @apply text-[3.125rem] relative;
        margin-bottom: 1rem;
        p {
          position: absolute;
        }
      }
      .title {
        @apply text-[1.375rem];
      }
      .text {
        @apply text-[1.125rem];
      }
      .btn {
        margin: 1rem auto 0 0;
      }
      .list-group {
        gap: .5rem;
      }
    }
    .swiper {
      overflow: hidden;
      padding: 5rem 0 4rem;
    }
    .swiper-wrapper {
      display: flex;
      flex-direction: row;
      gap: 0rem;
    }
    .swiper-slide {
      display: flex;
      flex-direction: row;
      gap: 4rem;
      //overflow-x: hidden;
    }
    .swiper-pagination {
      height: auto;
      width: 30px!important;
      z-index: 1;
      position: absolute;
      bottom: 45%;
      top: 43%;
      left: -3rem!important;
      .swiper-pagination-bullet {
        background: transparentize(#fff, .1);
      }
      .swiper-pagination-bullet-active {
        background: transparentize(#fff, .0);
      }
    }
    .swiper-container {
      display: flex;
      width: 50px;
      height: 100%;
      position: absolute;
      top: -125px;
      left: -50px;
      transform: rotate(90deg);
      z-index: 1;
      .swiper-button-next {
        right: -250px;
      }
    }
  }
}

//SECTION CONTACT
.strate__contact {
  &__formulaire {
    .modal {
      position: relative;
      top: inherit;
      .modal-container {
        min-height: inherit;
        height: auto;
      }
      .modal-inner {
        padding: 0;
        margin-right: 0;
        max-width: inherit;
      }
    }
    .o-image {
      @apply bottom-0;
    }
  }
  .modal--contact {
    @apply font-primary text-[1rem];

    @media screen and (min-width: $media-desktop) {
      h3 {
        @apply font-primary text-[1.5rem];
      }
      .input-group label {
        @apply text-[1rem];
      }
     .textarea {
       min-height: 200px;
     }
   }
  }
}
.contact__address {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .contact__address--head {
    @apply mb-4 md:mb-8;
    h3 {
      @apply  relative font-primary text-[1rem] md:text-[1.5rem];
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -1rem;
        width: 3rem;
        height: 2px;
        background: white;
      }
    }
  }
  .contact__address--body {
    ul {
      @apply gap-4 md:gap-8;
      display: flex;
      flex-direction: column;
    }
    li {
      @apply text-white text-[0.875rem] md:text-[1rem];
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}
.contact__reseaux {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .contact__reseaux--head {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3rem;
    h3 {
      @apply  relative font-primary text-[1rem] md:text-[1.5rem];
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -1rem;
        width: 3rem;
        height: 2px;
        background: white;
      }
    }
  }
  .link {
    display: flex;
    align-items: center;
    color: white;
    .icon {
      height: auto;
      width: 18px;
    }
    .text {
      @apply font-tertiary font-semibold text-sm;
    }
  }
  @media (min-width: 768px) {
    a.link {
      display: flex;
      align-items: center;
      .icon {
        height: auto;
        width: 28px;
      }
      .text {
        @apply text-2xl;
      }
    }
  }

}

//PAGE THEME FOOTER
.strate-theme-footer {
  @apply text-white min-h-[230px] md:min-h-[575px];
  .strate-theme-footer__steps {
    @apply flex flex-col gap-16 md:gap-32;
    .strate-theme-footer__strategy {
      @apply flex flex-col gap-16 md:flex-row md:gap-12 md:items-center justify-center;
    }

    .strate-theme-footer--content {
      @apply flex flex-col gap-4 md:gap-8 text-center;
    }

    h6 {
      @apply font-tertiary text-sm font-semibold leading-none md:text-[30px];
    }

    h3 {
      @apply font-tertiary text-2xl font-black leading-none md:text-[80px];
    }

    h4 {
      @apply font-tertiary text-2xl font-black leading-none md:text-[60px];
    }

    p {
      @apply font-tertiary text-sm font-normal leading-normal md:text-[22px];
      color: rgba(255, 255, 255, .5);
    }

    a {
      @apply text-center;
    }
  }
}
.strate-strategy-footer {
  background-image: url("../../src/assets/images/02-strategy/footer_strategy.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.strate-transformation-footer {
  background-image: url("../../src/assets/images/03-transformation/footer_transfo.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.strate-delivery-footer {
  background-image: url("../../src/assets/images/04-delivery/footer_delivery.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.strate-technology-footer {
  background-image: url("../../src/assets/images/05-technology/footer_technologie.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
}

//AUTRES
.mirror {
  transform: scaleX(-1);
}

//BLUR
.blur {
  height: 150px;
  width: 150px;
  background-size: cover;
  @media (min-width: 768px) {
    height: 300px;
    width:  300px;
  }
}
.blur-green {
  background-image: url("../assets/images/gradient/blured-green.png");
}
.blur-orange {
  background-image: url("../assets/images/gradient/blured-orange.png");
}
.blur-purple {
  background-image: url("../assets/images/gradient/blured-purple.png");
}
.blur-blue {
  background-image: url("../assets/images/gradient/blured-blue.png");
}

//GRADIENT FLASH
.gradient {
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    left: -50px;
    height: 200px;
    top: -50px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    @media (min-width: 768px) {
      top: -150px;
      left: -150px;
      height: 500px;
    }
  }
}
.gradient-blue {
  &:before {
    background-image: url("../assets/images/gradient/blured-blue.png");
  }
}
.gradient-yellow {
  &:before {
    background-image: url("../assets/images/gradient/bured-yellow.png");
  }
}
.gradient-green {
  &:before {
    background-image: url("../assets/images/gradient/blured-green.png");
  }
}
.gradient-orange {
  &:before {
    background-image: url("../assets/images/gradient/blured-orange.png");
  }
}
.gradient-purple {
  &:before {
    background-image: url("../assets/images/gradient/blured-purple.png");
  }
}
.gradient-pink {
  &:before {
    background-image: url("../assets/images/gradient/blured-pink.png");
  }
}
.gradient-rose {
  &:before {
    background-image: url("../assets/images/gradient/blured-rose.png");
  }
}

.gradient--header {
  position: relative;
  &:before {
    content:'';
    display: block;
    position: absolute;
    top: -750px;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 2px 0px 2px #000;
    height: 750px;
    width: 100%;
    z-index: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  }
  &.carriere {
    &:before {
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%);
    }
  }
  &.partenaire {
    &:before {
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%);
    }
  }
}
.gradient--h-50 {
  position: relative;
  &:before {
    content:'';
    display: block;
    position: absolute;
    top: -50vh;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 2px 0px 2px #000;
    height: 50vh;
    width: 100%;
    z-index: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%);
  }
}
.gradient--o-50 {
  position: relative;
  &:before {
    content:'';
    display: block;
    position: absolute;
    //top: calc(-100vh + -90px);
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 2px 0px 2px #000;
    //height: calc(100vh + 90px);
    height: 100vh;
    min-height: 990px;
    width: 100%;
    z-index: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.3) 100%);
  }
}

//LINES POINT COLORS
.line-point {
  width: 100%;
  height: auto;
}
.text-outline {
  color: transparent;
  -webkit-text-stroke: 1px white;
}

.accordion {
  position: relative;
  .button-box  {
    @apply w-full px-8 py-4 text-left;
    position: relative;
    display: flex;
    align-items: flex-start;
    &:before {
      content: '';
      display: block;
      height: 15px;
      width: 15px;
      border-radius: 50px;
      border: 3px solid rgba(255,255,255,1);
      position: absolute;
      left: -8px;
      top: 27px;
      background-color: black;
    }
    span {
      @apply font-primary font-extrabold text-2xl;
      display: inline-block;
      margin-right: 1rem;
    }
  }
  .content-box {
    @apply relative transition-all  duration-700 ;
  }
  .icon {
    transform: rotate(90deg);
  }
  .box {
    @apply pl-8;
  }
  li {
    border-left: 2px dotted white;
    margin-bottom: 2px;
  }
  h5 {
    @apply font-primary font-bold text-sm;
  }
  p {
    @apply font-tertiary font-normal text-xs text-eulidia-grey-500;
  }

  @media (min-width: 768px) {
    .button-box {
      @apply w-full px-4 py-4 text-left;
      position: relative;
      border-left: inherit ;
      border-right: 2px dotted white;
      margin-bottom: 0;
      padding-left: 0;
      width: 200px;
      min-width: 200px;
      max-width: 200px;
      &:before {
        content: '';
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 50px;
        border: 3px solid rgba(255,255,255,1);
        position: absolute;
        right: -11px;
        left: initial;
        //top: calc(50% - 8px);
        top: 40px;
        background-color: black;
      }
      span {
        @apply text-[40px];
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .box {
      @apply p-0 pl-16 pt-4;
    }
    .icon {
      display: none;
    }
    li {
      display: flex;
      flex-direction: row;
      background: transparent;
      border-left: inherit ;
    }
    h5 {
      @apply font-primary font-bold text-[40px];
    }
    p {
      @apply font-tertiary font-normal text-xl text-eulidia-grey-500;
    }
  }
}
.list-primary {
  &--group {
    li:last-child {
      border-bottom: 1px solid transparentize(#fff, .9);
    }
  }
  &--item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top: 1px solid transparentize(#fff, .9);
    padding: 1.5rem 0 1.875rem;
  }
  &--title {
    @apply text-[1.625rem] md:text-[2.75rem] font-primary font-medium tracking-tight;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
  &--text {
    @apply text-[0.875rem] md:text-[1.375rem] font-primary font-normal leading-relaxed md:ml-10;
  }
  .icon img {
    @apply h-[1.5rem] w-[1.5rem] md:h-[2.125rem] md:w-[2.125rem];
  }
}
.theme {
  &--purple {
    .gradient {
      &:before {
        background-image: url("../assets/images/gradient/blured-purple.png");
      }
    }
    .color {
      @apply text-eulidia-purple-2;
    }
  }
  &--green {
    .gradient {
      &:before {
        background-image: url("../assets/images/gradient/blured-green.png");
      }
    }
    .color {
      @apply text-eulidia-green;
    }
  }
  &--orange {
    .gradient {
      &:before {
        background-image: url("../assets/images/gradient/blured-orange.png");
      }
    }
    .color {
      @apply text-eulidia-orange;
    }
  }
  &--cyan {
    .gradient {
      &:before {
        background-image: url("../assets/images/gradient/blured-blue.png");
      }
    }
    .color {
      @apply text-eulidia-cyan;
    }
  }
  &--rose {
    .gradient {
      &:before {
        background-image: url("../assets/images/gradient/blured-rose.png");
      }
    }
    .color {
      @apply text-eulidia-rose;
    }
  }
}

.ml16 {
  overflow: hidden;
}
.ml16 .letter {
  display: inline-block;
  line-height: 1em;
}

br {
  &.m {
    display: inline-block;
    @media screen and (min-width: $media-desktop) {
      display: none;
    }
  }
  &.d {
    display: none;
    @media screen and (min-width: $media-desktop) {
      display: inline-block;
    }
  }
}

//Text scroll
.manifesto {
  .intro {
    .line {
      display: block;
      .content {
        display: inline-flex;
        align-content: center;
        align-items: center;
        position: relative;
        color: white;
        width: 100%;
        span {
          @apply font-bold tracking-tighter text-[1.375rem] md:text-[1.625rem] md:text-[4.375rem] md:leading-tight;
          white-space: nowrap;
        }
        span:first-child{
          opacity: .2;
        }
        .mask {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          height: 115%;
          transform-origin: 100% 50%;
          transform: translateZ(0);
          opacity: 1;
          background: transparent;
          white-space: nowrap;
          overflow: hidden;
          color: white;
          width: 0px;

        }
      }
    }
  }
}
@media screen and (min-width: $media-desktop) {
  .c-section.header.header__data-stories-detail {
    .header__data-stories-detail__top {
      .o-container {
        width: 100%;
      }
    }
  }
}


//Mail
.mail-message-confirmation {
  display: none;
}
.modal-container ::placeholder {
  color: transparentize(black,.5);
}
