.btn {
	@apply font-tertiary font-bold text-white border border-white rounded-full transition-all ease-out duration-300;
	background-color: transparentize(#fff,1);
	&:hover {
		@apply font-tertiary font-bold text-black border border-white rounded-full transition-all ease-out duration-300;
		background-color: transparentize(#fff,0);
	}
}
.btn-sm {
	@apply text-xs py-1 px-3;
	@media (min-width: 768px) {
		@apply py-1 px-3 text-sm leading-normal;
	}
}
.btn-md {
	@apply text-xs py-2 px-12;
	@media (min-width: 768px) {
		@apply py-3 text-xl;
	}
}
