//Geometos

@font-face {
	font-family: Geometos;
	font-weight: 400;
	font-style: normal;
	src: url("../assets/fonts/GEOMETOS/Geometos.ttf") format("truetype");
}

//Poppins

@font-face {
	font-family: Poppins;
	font-weight: 300;
	src: url("../assets/fonts/POPPINS/Poppins-Light.ttf") format("truetype");
}

@font-face {
	font-family: Poppins;
	font-weight: 400;
	src: url("../assets/fonts/POPPINS/Poppins-Regular.ttf") format("truetype");
}

@font-face {
	font-family: Poppins;
	font-weight: 500;
	src: url("../assets/fonts/POPPINS/Poppins-Medium.ttf") format("truetype");
}

@font-face {
	font-family: Poppins;
	font-weight: 600;
	src: url("../assets/fonts/POPPINS/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: Poppins;
	font-weight: 700;
	src: url("../assets/fonts/POPPINS/Poppins-Bold.ttf") format("truetype");
}

@font-face {
	font-family: Poppins;
	font-weight: 800;
	src: url("../assets/fonts/POPPINS/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
	font-family: Poppins;
	font-weight: 900;
	src: url("../assets/fonts/POPPINS/Poppins-Black.ttf") format("truetype");
}

//Source Sans Pro

@font-face {
	font-family: SourceSansPro;
	font-weight: 300;
	src: url("../assets/fonts/SOURCESSANSPRO/SourceSansPro-Light.ttf") format("truetype");
}

@font-face {
	font-family: SourceSansPro;
	font-weight: 400;
	src: url("../assets/fonts/SOURCESSANSPRO/SourceSansPro-Regular.ttf") format("truetype");
}

@font-face {
	font-family: SourceSansPro;
	font-weight: 600;
	src: url("../assets/fonts/SOURCESSANSPRO/SourceSansPro-Semibold.ttf") format("truetype");
}

@font-face {
	font-family: SourceSansPro;
	font-weight: 700;
	src: url("../assets/fonts/SOURCESSANSPRO/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
	font-family: SourceSansPro;
	font-weight: 900;
	src: url("../assets/fonts/SOURCESSANSPRO/SourceSansPro-Black.ttf") format("truetype");
}


/* Real outline for modern browsers */
@supports((text-stroke: 1px white) or (-webkit-text-stroke: 1px white)) {
	.font-outline {
		color: transparent;
		-webkit-text-stroke: 1px white;
		text-stroke: 1px white;
		text-shadow: none;
	}
	.font-outline-yellow {
		color: transparent;
		-webkit-text-stroke: 1px #FFFF3A;
		text-stroke: 1px #FFFF3A;
		text-shadow: none;
	}
}
