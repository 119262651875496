footer {
	@apply bg-black;
	.c-footer {
		border-top: 1px solid transparentize(white, .8);
		@apply text-white py-8 ;
	}
	.group-items {
		h2 {
			@apply font-tertiary font-black text-base uppercase;
		}
	}
	.list-items {
		@apply font-tertiary font-normal text-sm;
		a {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	address {
		font-style: normal;
	}
	li {
		text-align: center;
		margin: 0 auto;
	}
	.about-us,.framework,.data-story {
		display: none;
		visibility: hidden;
	}
	.part-bottom {
		> div:nth-child(1) {
			display: none;
		}
		> div:nth-child(2) {
			li { margin: 0 auto;}
		}
	}
	.icon-footer {
		width: 3rem;
		height: 3rem;
		.icon {
			width: 1.25rem;
			height: 1.25rem;
		}
	}
	.c-footer {
		.group-items:has(.logo-eulidia) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	@media (min-width: 768px) {
		.c-footer {
			@apply py-8 ;
		}
		.c-footer {
			.group-items:has(.logo-eulidia) {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
		li {
			text-align: left;
			margin: inherit;
		}
		.about-us,.framework,.data-story {
			display: inherit;
			visibility: visible;
		}
		.part-bottom {
			> div:nth-child(1) {
				display: inherit;
			}
			> div:nth-child(2) {
				li { margin: inherit;}
			}
		}

	}
}