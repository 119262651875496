
//TIME
* [class*="duration-500"] {
	@apply transition-all duration-500;
}
* [class*="duration-1000"] {
	@apply transition-all duration-1000;
}
* [class*="duration-2000"] {
	@apply transition-all duration-2000;
}
* [class*="duration-3000"] {
	@apply transition-all duration-3000;
}
* [class*="duration-4000"] {
	@apply transition-all duration-4000;
}
* [class*="duration-5000"] {
	@apply transition-all duration-5000;
}

//DELAY
* [class*="delay-100"] {
	@apply delay-100;
}
* [class*="delay-200"] {
	@apply delay-200;
}
* [class*="delay-300"] {
	@apply delay-300;
}
* [class*="delay-500"] {
	@apply delay-500;
}
* [class*="delay-700"] {
	@apply delay-700;
}
* [class*="delay-1000"] {
	@apply delay-1000;
}

//FADE

* [class*="opacity-25"] {
	@apply opacity-25;
}
* [class*="opacity-50"] {
	@apply opacity-50;
}
* [class*="opacity-75"] {
	@apply opacity-75;
}
* [class*="opacity-100"] {
	@apply opacity-100;
}

//TRANSLATE Y

* [class*="translate-vertical"] {
	@apply translate-y-0;
}
* [class*="translate-horizontal"] {
	@apply translate-x-0;
}

//TRANSLATE

* [class*="h24"] {
	@apply h-24;
}
* [class*="h32"] {
	@apply h-32;
}
* [class*="wfull"] {
	@apply w-full;
}
* [class*="-white"] {
	@apply text-white;
}


.show--off {
	@apply opacity-0 transition-transform delay-700 duration-5000 translate-y-6 ease-in-out;
}
.show--on {
	@apply opacity-100 transition-transform delay-700 duration-5000 translate-y-0 ease-in-out;
}
