nav {
	display: flex;
	flex-wrap: wrap;
	color: white;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: auto;
	z-index: 100;
	background: rgba(0, 0, 0, 0.3);
	-webkit-transform: translate3d(0,0,0);
	box-shadow: none;
	&:after {
		content: "";
		display: block;
		background: none;
		position: fixed;
		z-index: -1;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		backdrop-filter: blur(20px);
		-webkit-backdrop-filter: blur(20px);

	}
	>.contain {
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
	}
	.branding {
		position: relative;
		display: flex;
		flex: 1 1 50%;
		align-items: center;
		order: 1;
		.button-burger {
			position: relative;
			height: 24px;
			width: 24px;
			display: block;
			img {
				position: absolute;
			}
		}
		.button-burger.close {
			img:first-child {
				transform: rotate(0deg);
				top: 5px;
				left: 5px;
				opacity: 1;
				@apply transition-all duration-300 ease-in-out ;
			}
			img:last-child {
				transform: rotate(180deg);
				top: 16px;
				left: 5px;
				opacity: 0;
				@apply transition-all duration-300 ease-in-out ;
			}
		}
		.button-burger.open {
			img:first-child {
				transform: rotate(0deg);
				top: -16px;
				left: 5px;
				opacity: 0;
				@apply transition-all duration-300 ease-in-out ;
			}
			img:last-child {
				transform: rotate(180deg);
				top: 5px;
				left: 5px;
				opacity: 1;
				@apply transition-all duration-300 ease-in-out ;
			}
		}
	}
	.button-contact {
		display: flex;
		justify-content: flex-end;
		flex: 1 1 50%;
		order: 2;
	}
	.menu-container {
		display: flex;
		flex-direction: column;
		flex: 0 1 100%;
		//border: 1px solid red;
		order: 3;
		height: 100vh;
		overflow: scroll;
	}
	.list {
		display: flex;
		flex-direction: column;
	}

	.branding ~ .menu-container {
		//opacity: 0;
		//height: 0vh;
		//overflow: hidden;
		//@apply transition-all duration-300  ease-in-out ;
	}
	.branding.is-active ~ .menu-container {
		opacity: 1;
		min-height:calc(100vh - 50px);
		height: auto;
		overflow: auto;
		@media (min-width: 768px) {
			min-height: auto;
		}
	}

	.title {
		@apply font-primary font-bold uppercase text-2xl;
		text-align: center;
	}
	.group-items {
		.item {
			@apply flex justify-center my-2;
			a {
				@apply font-tertiary font-normal text-sm leading-normal;
				max-width: 200px;
				text-align: center;
			}
		}
	}
	.icon {
		display: none;
	}

	@media (max-width: 767px) {
		> .contain {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			justify-content: space-between;
			flex-direction: row;
			flex: 1;
			margin: 0;
		}
		.group-items {
			display: flex !important;
			flex-direction: column;
		}
		.logo-eulidia {
			img {
				max-height: 12px;
				max-width: 100px;
			}
		}
	}

	@media (min-width: 768px) {
		min-height: 90px;
		.contain {
			@apply container;
			max-width: 1024px;
			display: flex;
			justify-content: space-between;
			flex: inherit;
			width: 100%;
		}
		.branding {
			flex: 0 1 100px;
			order: 1;
			height: 36px;
			.button-burger {
				display: none;
				pointer-events: none;
			}
			.logo-eulidia {
				display: flex;
				min-width: 145px;
				img {
					width: 100%;
				}
			}
		}
		.button-contact {
			flex: 0 1 100px;
			order: 3;
			height: 36px;
			transition: flex 0s ease !important;
		}
		.menu-container {
			display: flex!important;
			flex-direction: row;
			flex: 1 1 60%;
			order: 2;
			width: 100%;
			@apply mx-8;
			.contain {
				display: flex;
				justify-content: flex-end;
				width: 100%;
			}
		}
		.button-contact, .branding {
			display: flex;
			align-items: center;
			height: 64px;
			> * {
				height: 32px;
			}
		}

		.branding ~ .menu-container {
			opacity: 1;
			min-height: 50px;
			height: auto;
			overflow: hidden;
		}
		button, .title {
			height: auto;
			display: flex;
			align-items: center;
			padding: 0rem;
			transition: all 0s ease !important;
		}

		.group-items .item {
			@apply my-0;
			&:first-child {
				margin-top: 0.5rem;
			}
			&:last-child {
				margin-bottom: 1.5rem;
			}
		}
		.title ~ .group-items {
			opacity: 1;
			height: auto;
			overflow: hidden;

			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 1.5rem;
			padding: 0rem;
			//transition: all 0s ease !important;

			@apply transition-all duration-300  ease-in-out ;
			.item {
				justify-content: flex-start;
			}
			a {
				text-align: left;
				@apply text-base;

				&:hover {
					@apply text-eulidia-green;
				}
			}
		}
		.title.is-active ~ .group-items {
			opacity: 1;
			height: auto;
			overflow: auto;
			display: flex;
			flex-direction: column;
			@apply mb-4;
		}

		.title {
			@apply font-tertiary normal-case font-bold text-base;
			text-align: left;
			height: 64px;
			.icon {
				display: block;
				padding: 1rem;
				transform: rotate(90deg);
			}
			&.is-active {
				.icon {
					transform: rotate(-90deg);
				}
			}
		}
		a.title ~ .group-items {
			display: none;
		}
		.list {
			//min-width: 150px;
		}

		.nav-title.close {
			.icon {
				position: relative;
			}
			.icon img {
				position: absolute ;
				left: 13px;
				top: 0px;
			}
			.icon img:first-child {
				transform: rotate(0deg);
				left: 13px;
				opacity: 1;
				@apply transition-all duration-300 ease-in-out ;
			}
			.icon img:last-child {
				transform: rotate(180deg);
				opacity: 0;
				left: 0px;
				@apply transition-all duration-300 ease-in-out ;
			}
		}
		.nav-title.open {
			.icon {
				position: relative;
			}
			.icon img {
				position: absolute ;
				left: 13px;
				top: 0px;
			}
			.icon img:first-child {
				transform: rotate(0deg);
				opacity: 0;
				left: 25px;
				@apply transition-all duration-300 ease-in-out ;
			}
			.icon img:last-child {
				transform: rotate(180deg);
				left: 13px;
				opacity: 1;
				@apply transition-all duration-300 ease-in-out ;
			}
		}
		.group-items.close {
			height: 0px;
			display: block!important;
			@apply transition-all duration-300 ease-in-out ;
		}
		.group-items.open {
			height: auto;
			@apply transition-all duration-300 ease-in-out ;
		}

	}
}

.no-clickable-mobile {
	pointer-events: none;
	@media (min-width: 768px) {
		pointer-events: inherit;
	}
}

.icon-reseaux {
	width: 3rem;
	height: 3rem;
	.icon {
		display: flex !important;
		width: 1.25rem;
		height: 1.25rem;
	}
}
