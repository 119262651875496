
// SWIPER #datapureplayer
//Slide inside swiper
.c-card {
	@apply bg-black bg-center bg-cover p-3 ;
	.swiper-head {
		display: flex;
	}
	h6 {
		@apply text-5xl;
	}
	h3 {
		@apply text-base;
	}
	p {
		@apply text-xs;
	}
	.icon {
		width: 38px;
		height: 38px;
	}
	&.card-green {
		background-image: url("../../src/assets/images/01-homepage/green.jpg");
	}
	&.card-blue {
		background-image: url("../../src/assets/images/01-homepage/blue.jpg");
	}
	&.card-orange {
		background-image: url("../../src/assets/images/01-homepage/orange.jpg");
	}
	&.card-pink {
		background-image: url("../../src/assets/images/01-homepage/pink.jpg");
	}
	&.card-purple {
		background-image: url("../../src/assets/images/01-homepage/purple.jpg");
	}
	&.card-yellow {
		background-image: url("../../src/assets/images/01-homepage/yellow.jpg");
	}
	@media (min-width: 768px) {
		@apply p-6;
		.icon {
			width: 64px;
			height: 64px;
		}
		h6 {
			@apply text-6xl;
		}
		h3 {
			@apply text-xl;
		}
		p {
			@apply text-xs;
		}
	}
}
.c-card--secondary {
	@apply bg-black bg-center bg-cover p-[1.25rem]  ;
	.swiper-head {
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
	}
	.swiper-body {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	h6 {
		@apply text-[1.625rem] ;
	}
	p {
		@apply text-[0.875rem] ;
	}
	ul {
		display: flex;
		flex-direction: column;
		gap: .65rem;
		li {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 0.65rem;
			font-size: 0.875rem;
			i {
				padding-top: 2px;
			}
		}
	}
	.icon {
		width: 38px;
		height: 38px;
	}
	&.card-green {
		background-image: url("../../src/assets/images/01-homepage/green.jpg");
	}
	&.card-blue {
		background-image: url("../../src/assets/images/01-homepage/blue.jpg");
	}
	&.card-orange {
		background-image: url("../../src/assets/images/01-homepage/orange.jpg");
	}
	&.card-pink {
		background-image: url("../../src/assets/images/01-homepage/pink.jpg");
	}
	&.card-purple {
		background-image: url("../../src/assets/images/01-homepage/purple.jpg");
	}
	&.card-yellow {
		background-image: url("../../src/assets/images/01-homepage/yellow.jpg");
	}
	@media (min-width: 768px) {
		@apply p-[3.125rem];
		.swiper-body {
			gap: 1.25rem;
		}
		.swiper-body {
			gap: 2rem;
		}
		.icon {
			width: 100%;
			max-width: 64px;
			height: auto;
			max-height: 64px;
		}
		h6 {
			@apply text-[4rem];
		}
		p {
			@apply text-[1.375rem];
		}
		ul {
			gap: 1rem;
			li {
				gap: 0.875rem;
				font-size: 1.25rem;
				i {
					padding-top: 5px;
				}
			}
		}
	}
}
.c-card--tertiary {
	@apply bg-black bg-center bg-cover p-[1.25rem];
	.swiper-head {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 1rem;
		margin-bottom: 1rem;
	}
	.swiper-body {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	h6 {
		@apply text-[1.625rem] ;
	}
	p {
		@apply text-[0.875rem] ;
	}
	ul {
		display: flex;
		flex-direction: column;
		gap: .65rem;
		li {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 0.65rem;
			font-size: 0.875rem;
			i {
				padding-top: 2px;
				max-width: 12px;
				max-height: 12px;
			}
		}
	}
	.icon {
		min-width: 38px;
		min-height: 38px;
		width: 38px;
		height: 38px;
		img {
			object-fit: contain;
		}
	}
	.btn {
		margin: 0 auto 0 0;
	}
	&.card-blue-1 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-blue-1.png");
	}
	&.card-blue-2 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-blue-2.png");
	}
	&.card-blue-3 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-blue-3.png");
	}
	&.card-blue-4 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-blue-4.jpg");
	}
	&.card-blue-5 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-blue-5.jpg");
	}
	&.card-blue-6 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-blue-6.png");
	}
	&.card-red-1 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-red-1.png");
	}
	&.card-red-2 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-red-2.png");
	}
	&.card-green-1 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-green-1.jpg");
	}
	&.card-green-2 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-green-2.png");
	}
	&.card-orange-1 {
		background-image: url("../../src/assets/images/10-partenaires/bg-card-orange-1.png");
	}

	@media (min-width: 768px) {
		@apply p-[3.125rem];
		.swiper-head {
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}
		.swiper-body {
			gap: 2rem;
		}
		.icon {
			width: 100%;
			max-width: 64px;
			height: auto;
			max-height: 64px;
		}
		h6 {
			@apply text-[4rem];
		}
		p {
			@apply text-[1.375rem];
		}
		ul {
			gap: 1rem;
			li {
				display: flex;
				flex-direction: row;
				gap: 0.875rem;
				font-size: 1.25rem;
				align-items: flex-start;
			}
		}
		i {
			max-width: 16px!important;
			max-height: 16px!important;
		}
	}
}

//Slide + Pagination controller
.swiper-datapureplayer {
	max-width: 700px;
	padding-bottom: 1rem !important;
	padding-left: 40px!important;
	padding-right: 40px!important;
	.swiper-pagination {
		bottom: 0!important;
	}
	.swiper-pagination-bullet {
		height: 10px ;
		width:  10px ;
		@apply bg-white opacity-20;
	}
	.swiper-pagination-bullet-active {
		@apply bg-eulidia-green opacity-100;
	}
	@media (min-width: 768px) {
		padding-left: 0px!important;
		padding-right: 0px!important;
	}
}

//Arrow controller
.swiper-container {
	.swiper-button-prev,
	.swiper-button-next {
		height: 60px;
		width: 73px;
		&:after {
			display: none;
			overflow: hidden;
		}
	}
	.swiper-button-prev {left: 0;}
	.swiper-button-next {right: 0; transform: rotate(180deg);}
}

// SWIPER #TechnoAddict
.swiper-techno-addict {
	padding-left: 40px!important;
	padding-right: 40px!important;
	.c-card {
		min-height: 240px;
		max-width: 166px;
		.icon {
			width: 34px;
			height: auto;
			overflow: hidden;
			border-radius: 3px;
			@apply mb-4;
		}
		h3 {
			@apply text-base mb-2;
		}
		p {
			@apply text-xs;
			visibility: hidden;
			display: none;
		}
		@apply bg-black bg-center;
		background-size: 110%;
		&.card-green {
			background-image: url("../../src/assets/images/01-homepage/techno-addict-bg-cloud.jpg");
		}
		&.card-blue {
			background-image: url("../../src/assets/images/01-homepage/techno-addict-bg-data-science.jpg");
		}
		&.card-red {
			background-image: url("../../src/assets/images/01-homepage/techno-addict-bg-visualisation.jpg");
		}
		&.card-yellow {
			background-image: url("../../src/assets/images/01-homepage/techno-addict-bg-management.jpg");
		}
	}
	@media (min-width: 768px) {
		padding-left: 0px!important;
		padding-right: 0px!important;
		.c-card {
			min-height: 360px;
			min-width: 250px;
			.icon {
				width: 54px;
				height: auto;
				overflow: hidden;
				border-radius: 3px;
				@apply mb-2;
			}
			h3 {
				@apply text-2xl;
			}
			p {
				@apply text-base;
				visibility: visible;
				display: block;
			}
		}
	}
}

// CAROUSEL SCROLL #DataLovers
.swiper-data-lovers {
	padding: 0 40px!important;
	overflow: visible!important;
	.swiper-slide {
		max-width: 185px;
	}
	.c-card {
		position: relative;
		display: flex;
		//flex: 1;
		padding: 0;
		min-width: 160px;
		max-width: 185px;

		.c-blur {
			position: absolute;
			overflow: hidden;
			display: block;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100px;
			width: 100%;
		}
		.o-blur {
			position: absolute;
			bottom: -20px;
			left: -20px;
			right: -20px;
			top: -20px;
			height: calc(100px + 40px);
			width: calc(100% + 40px);
			z-index: 1;
			background-repeat: repeat;
			background-size: cover;
			background-position: bottom;
			filter: blur(15px);
			-webkit-filter: blur(15px);
			overflow: hidden;
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				display: block;
				width: 100%;
				height: 100px;
			}
		}
		.swiper-body {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100px;
			z-index: 10;
			@apply p-3 text-white;
		}
		.content {}
		.redirection {
			display: flex;
			flex: 1;
			height: 100%;
			flex-direction: row;
			align-items: stretch;
			justify-content: stretch;
			align-self: end;
		}
		h6 {
			@apply font-tertiary text-base font-black;
		}
		h3 {
			@apply font-tertiary text-base font-black;
		}
		p {
			@apply font-tertiary font-normal;
			visibility: hidden;
			display: none;
		}
		a {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			@apply font-tertiary text-xs font-medium;
		}

		&.card-red {
			background-image: url("../../src/assets/images/01-homepage/datalovers-red.jpg");

			.o-blur {
				background-image: url("../../src/assets/images/01-homepage/datalovers-red.jpg");
			}
		}
		&.card-green {
			background-image: url("../../src/assets/images/01-homepage/datalovers-green.jpg");

			.o-blur {
				background-image: url("../../src/assets/images/01-homepage/datalovers-green.jpg");
			}
		}
		&.card-yellow {
			background-image: url("../../src/assets/images/01-homepage/datalovers-yellow.jpg");

			.o-blur {
				background-image: url("../../src/assets/images/01-homepage/datalovers-yellow.jpg");
			}
		}
		&.card-blue {
			background-image: url("../../src/assets/images/01-homepage/datalovers-blue.jpg");

			.o-blur {
				background-image: url("../../src/assets/images/01-homepage/datalovers-blue.jpg");
			}
		}
		&.card-purple {
			background-image: url("../../src/assets/images/01-homepage/datalovers-purple.jpg");

			.o-blur {
				background-image: url("../../src/assets/images/01-homepage/datalovers-purple.jpg");
			}
		}
	}
	@media (max-width: 767px) {
		.swiper-container {
			display: none;
		}
	}
	@media (min-width: 768px) {
		.swiper-slide {
			max-width: 400px;
		}
		.c-card {
			width: 400px;
			min-width: 400px;
			max-width: 400px;
			flex: 1;
			.c-blur {
				height: 215px;
			}
			.o-blur {
				height: calc(215px + 40px);
				&:after {
					height: 215px;
				}
			}
			.swiper-body {
				height: 215px;
				@apply p-6;
			}
			.redirection {
				flex: 1 1 90%;
				justify-content: flex-end;
				align-items: flex-end;
			}
			.content {
				flex: 0 0 55%;
			}
			h6 {
				@apply text-2xl mb-2;
			}
			h3 {
				@apply text-3xl mb-2;
			}
			p {
				@apply text-xs;
				visibility: visible;
				display: flex;
				height: 20px;
			}
			a {
				@apply font-tertiary text-base;
			}
		}
	}
}

// Card profile Philosophie
.c-card-profile {
	@apply text-white px-10;
	h3 {
		@apply font-tertiary font-extrabold text-2xl mb-2 leading-none;
	}
	h4 {
		@apply font-tertiary font-normal text-sm tracking-wider mb-3;
	}
	a {
		display: flex;
		align-items: center;
		.icon {
			height: auto;
			width: 18px;
		}
		.text {
			@apply font-tertiary font-semibold text-sm;
		}
	}
	.picture {
		@apply shadow-2xl;
		img {
			width: 100%;
			height: 280px;
			object-fit: cover;
		}
	}
	.content {
		@apply absolute bottom-0 top-0 left-0 right-0 mx-10 p-5;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		height: 280px;

	}
	@media (min-width: 768px) {
		@apply px-0;
		width: 390px;
		height: 540px;
		h3 {
			@apply text-5xl leading-none;
		}
		h4 {
			@apply text-lg ;
		}
		a {
			display: flex;
			align-items: center;
			.icon {
				height: auto;
				width: 28px;
			}
			.text {
				@apply text-2xl;
			}
		}
		.picture {
			@apply shadow-2xl;
			img {
				width: 100%;
				height: 540px;
				object-fit: cover;
			}
		}
		.content {
			@apply absolute bottom-0 top-0 left-0 right-0 mx-0 p-10 ;
		}
	}
}
.swiper-team-management {
	padding: 0 40px !important;
	.group-card {
		column-gap: 0!important;
		//@apply mx-10;
	}
	.c-card-profile {
		padding: 0;
		width: 240px;
	}
	.content {
		margin: 0;
	}
	@media (min-width: 768px) {
		padding: 0 !important;
		overflow: visible !important;
		.group-card {
			column-gap: 0!important;
		}
		.c-card-profile {
			max-width: 390px;
			width: 100%;
		}
		.content {
			height: auto;
		}
	}
}
.swiper-team-direction {
	padding: 0 40px !important;
	.group-card {
		column-gap: 0!important;
		//@apply mx-10;
	}
	.c-card-profile {
		padding: 0;
		width: 240px;
	}
	.content {
		margin: 0;
	}
	@media (min-width: 768px) {
		padding: 0 !important;
		overflow: visible !important;
		.group-card {
			column-gap: 0!important;
		}
		.c-card-profile {
			max-width: 390px;
			width: 100%;
		}
		.content {
			height: auto;
		}
	}
}
.swiper-container-direction {
	display: none;
	@media (min-width: 768px) {
		display: block;
	}
	z-index: 1000;
	bottom: 280px;
	position: absolute;
	left: 2rem;
	right: 2rem;
}
.swiper-container-management {
	display: none;
	@media (min-width: 768px) {
		display: block;
	}
	z-index: 1000;
	bottom: 280px;
	position: absolute;
	left: 2rem;
	right: 2rem;
}
.swiper-container-paternaires {
	display: none;
	@media (min-width: 768px) {
		display: block;
	}
	z-index: 1000;
	bottom: 560px;
	position: absolute;
	left: 2rem;
	right: 2rem;
}

// Card Technologie
.card {
	@apply relative flex flex-col overflow-hidden md:min-h-[575px] md:max-h-[575px];
	.thumb {
		@apply absolute top-0 bottom-0 left-0 right-0;
		opacity: 1;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	.content {
		@apply relative md:flex md:flex-col md:flex-1 bg-black/60;
	}
	.head {
		@apply p-5 md:p-8 text-white min-h-[180px] md:flex-1 md:leading-relaxed;
		p {
			@apply font-tertiary text-[10px] text-white font-black md:text-[19px];
		}

		h6 {
			@apply font-tertiary text-base font-black md:text-[24px];
		}
	}
	.body {
		@apply flex flex-col gap-y-2 p-5 text-white md:gap-y-4 md:px-8 md:py-10;
		backdrop-filter: blur(15px);
		-webkit-backdrop-filter: blur(151px);
		position: relative;

		h3 {
			@apply font-tertiary font-bold text-[18px] text-white leading-none tracking-normal
			md:text-[32px];
		}

		p {
			@apply font-tertiary font-normal opacity-75 text-[12px] text-white leading-none tracking-wider
			md:text-[16px];
		}

		a {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			@apply font-tertiary text-xs font-medium
			md:text-[19px];
		}
	}
	&__green {
		.body {
			@apply bg-eulidia-green/40;
		}
	}
	&__orange {
		.body {
			@apply bg-eulidia-orange/40;
		}
	}
	&__cyan {
		.body {
			@apply bg-eulidia-cyan/40;
		}
	}
	&__pink {
		.body {
			@apply bg-eulidia-pink/40;
		}
	}
	&__neutral {
		.body {
			@apply bg-white/10;
		}
	}
	&__last {
		@apply hidden md:flex;
		position: relative;
		opacity: .5;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 10;
			display: block;
			background: rgb(0, 0, 0);
			background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.65) 75%, rgba(0, 0, 0, 0) 100%);
		}
	}
}

.swiper-data-stories {
	width: 100%;
	overflow: visible!important;
	.swiper-slide {
		@apply w-full md:w-[335px];
	}
	@media (min-width: 768px) {
		.card {
			max-height: 420px;
			min-height: 420px;
			.head {
				@apply p-8;
				p {
					@apply text-sm;
				}
				h6 {
					@apply text-base;
				}
			}
			.body {
				@apply p-8;
				h3 {
					@apply text-2xl;
				}
				p {
					@apply text-xs;
				}
				a {
					@apply text-sm;
				}
			}
		}
	}
}
