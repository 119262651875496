.modal {
	@apply top-0 left-0 flex items-center justify-center w-full;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 99;
	top: 0px;
	~.overlay {
		width: 100%;
		height: calc(100% - 54px);
		position: fixed;
		top: 54px;
		left: 0;
		background: black;
		display: block!important;
	}

	&.close {
		.modal-container {
			backdrop-filter: blur(0px);
			-webkit-backdrop-filter: blur(0px);
			transition: all 1s ease-in-out;
		}
		~.overlay {
			opacity: 0;
			z-index: -1;
			transition: all .6s cubic-bezier(.4,0,0,1);
		}
	}
	&.open  {
		.modal-container {
			backdrop-filter: blur(20px);
			-webkit-backdrop-filter: blur(20px);
			transition: all 1s ease-in-out;
		}
		~.overlay {
			opacity: 0.27;
			z-index: 10;
			transition: all .6s cubic-bezier(.4,0,0,1);
		}
	}
}
.modal-container {
	margin: auto;
	max-width: 100%;
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 54px);
	border-radius: 0;
}
.modal-inner {
	max-width: 992px;
	width: 100%;
	margin: 0 auto;
	padding: 5rem 2rem 1rem;
	overflow-y: auto;
}
.modal-header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	img {
		height: 1.5rem;
		width: 1.5rem;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		bottom: -1rem;
		width: 3rem;
		height: 2px;
		@apply bg-white;
	}
}
[x-cloak] {
	display: none !important;

}
.input-group {
	label {
		@apply py-3 text-sm;
	}
}
.input {
	@apply py-1 px-2 md:py-3 md:px-3 text-black text-sm font-primary font-normal rounded-none min-h-[44px] md:min-h-[54px];
}
textarea {
	@apply py-3 px-3 md:py-3 md:px-3 text-black text-sm font-primary font-normal rounded-none min-h-[44px];
	//padding: 1rem;
}
textarea::placeholder {
	@apply text-black;
}
.modal--contact {
	.modal-inner {
		@apply flex flex-col gap-8;
		@media screen and (min-width: $media-desktop) {
			//padding-bottom: 7rem;
			//overflow-y: auto;
			//height: 100vh;
			//justify-content: center;
			//min-height:1000px;
		}
		form {
			@apply flex flex-col md:gap-8;
		}
	}
}

@media screen and (min-width: $media-desktop) {
	.modal {
		top: 90px;
		~.overlay {
			height: calc(100% - 90px);
			top: 90px;
		}
	}
	.modal-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		min-height: calc(100vh - 90px);
	}
	.modal-inner {
		padding-top: 0;
	}
	.modal--contact {
		.textarea {
			min-height: 30vh;
			max-height: 500px;
		}
	}
}
